(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/scrollable-button-group/assets/javascripts/scrollable-button-group.js') >= 0) return;  svs.modules.push('/components/tipsen/scrollable-button-group/assets/javascripts/scrollable-button-group.js');
"use strict";

const {
  ReactIcon
} = svs.ui;
const {
  useEffect,
  useRef,
  Children,
  cloneElement,
  Fragment,
  useMemo
} = React;
const mapChildren = (children, firstRef, lastRef) => Children.map(children, (child, index) => {
  if (React.isValidElement(child)) {
    if (child.type === Fragment) {
      return mapChildren(child.props.children, firstRef, lastRef);
    }
    if (index === 0) {
      return cloneElement(child, {
        elemRef: firstRef
      });
    } else if (index === Children.count(children) - 1) {
      return cloneElement(child, {
        elemRef: lastRef
      });
    }
    return child;
  }
  return child;
});
const SCROLL_FACTOR = 0.7;
const ScrollableButtonGroup = _ref => {
  let {
    children,
    className = '',
    containerSelector
  } = _ref;
  const scrollableButtonGroupRef = useRef();
  const scrollableButtonGroupContentRef = useRef();
  const arrowPrevRef = useRef();
  const arrowNextRef = useRef();
  const firstButtonRef = useRef();
  const lastButtonRef = useRef();
  useEffect(() => {
    const scrollableButtonGroup = scrollableButtonGroupRef.current;
    const arrowPrev = arrowPrevRef.current;
    const arrowNext = arrowNextRef.current;
    const firstButton = firstButtonRef.current;
    const lastButton = lastButtonRef.current;
    const buttonToArrow = new Map([[firstButton, arrowPrev], [lastButton, arrowNext]]);
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        const arrow = buttonToArrow.get(entry.target);
        if (arrow) {
          arrow.toggleAttribute('disabled', entry.isIntersecting);
        }
      }
    }, {
      root: scrollableButtonGroup,
      threshold: 0.5
    });
    observer.observe(firstButton);
    observer.observe(lastButton);
    let resizeObserver;
    const containerEl = document.querySelector(containerSelector);
    if (containerEl) {
      resizeObserver = new ResizeObserver(containerEl => {
        if (scrollableButtonGroupRef.current) {
          scrollableButtonGroupRef.current.style.maxWidth = "".concat(containerEl[0].target.offsetWidth, "px");
        }
      });
      resizeObserver.observe(containerEl);
    }
    return () => {
      if (firstButton && lastButton) {
        observer.unobserve(firstButton);
        observer.unobserve(lastButton);
      }
      if (resizeObserver) {
        resizeObserver.unobserve(containerEl);
      }
    };
  }, [containerSelector]);
  const referencedChildren = useMemo(() => mapChildren(children, firstButtonRef, lastButtonRef), [children]);
  const handlePrev = () => {
    const {
      scrollLeft,
      offsetWidth
    } = scrollableButtonGroupContentRef.current;
    const nextScrollPosition = scrollLeft <= offsetWidth ? 0 : scrollLeft - Math.ceil(offsetWidth * SCROLL_FACTOR);
    scrollableButtonGroupContentRef.current.scroll({
      left: nextScrollPosition,
      behavior: 'smooth'
    });
  };
  const handleNext = () => {
    const {
      scrollLeft,
      scrollWidth,
      offsetWidth
    } = scrollableButtonGroupContentRef.current;
    const nextScrollPosition = scrollLeft + offsetWidth >= scrollWidth - offsetWidth ? scrollWidth - offsetWidth : scrollLeft + Math.ceil(offsetWidth * SCROLL_FACTOR);
    scrollableButtonGroupContentRef.current.scroll({
      left: nextScrollPosition,
      behavior: 'smooth'
    });
  };
  let rootClassName = 'scrollable-button-group';
  if (className !== '') {
    rootClassName += " ".concat(className);
  }
  return React.createElement("div", {
    className: rootClassName,
    ref: scrollableButtonGroupRef
  }, React.createElement("button", {
    className: "scrollable-button-group-arrow scrollable-button-group-arrow-prev",
    onClick: handlePrev,
    ref: arrowPrevRef,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "menu-left",
    size: "300"
  })), React.createElement("div", {
    className: "scrollable-button-group-content",
    ref: scrollableButtonGroupContentRef
  }, referencedChildren), React.createElement("button", {
    className: "scrollable-button-group-arrow scrollable-button-group-arrow-next",
    onClick: handleNext,
    ref: arrowNextRef,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "menu-right",
    size: "300"
  })));
};
setGlobal('svs.components.tipsen.scrollableButtonGroup.ScrollableButtonGroup', ScrollableButtonGroup);

 })(window);